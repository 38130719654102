.wrapper {
  &:global(.layout-align-left) {
    @apply max-w-8xl mx-auto;
    & > * {
      @apply max-w-1xl;
    }
  }

  &:global(.layout-2-col) {
    @apply columns-1 md:columns-2 gap-10;
  }
}

.coreList {
  &:global(.serif) {
    @apply font-serif;
  }

  &:global(.sans) {
    @apply font-sans;
  }

  & ul {
    @apply list-disc pl-5;
  }

  & ol {
    @apply list-decimal pl-5;
  }

  & li {
    @apply pl-1 pb-2;

    &:last-of-type {
      @apply pb-0;
    }

    & a {
      @apply underline;
    }
  }

  &:global(.arrows) ol,
  &:global(.arrows) ul {
    @apply pl-0;
  }

  &:global(.arrows) li {
    @apply relative list-none pb-6 flex items-start pl-0;

    &:last-of-type {
      @apply pb-0;
    }
  }

  & :global(.arrows) {
    background-image: url("/icons/arrow.svg");
    background-size: 16px;
    @apply block relative mr-4 w-8 h-8 bg-summer-green-100 shrink-0 rounded;
    @apply bg-no-repeat bg-center;
  }

  &:global(.check) ol,
  &:global(.check) ul {
    @apply pl-0;
  }

  &:global(.check) li {
    @apply relative list-none pb-6 flex items-start pl-0;

    &:last-of-type {
      @apply pb-0;
    }
  }

  & :global(.check) {
    background-image: url("/icons/checkmark.svg");
    background-size: 11px;
    @apply block w-6 h-6 mt-[3px] ml-[8px] mr-4 bg-green-100 rounded-full shrink-0;
    @apply bg-no-repeat bg-center;
  }

  &:global(.numeric) ol,
  &:global(.numeric) ul {
    @apply pl-0;
  }

  &:global(.numeric) {
    counter-reset: custom-list-counter;
  }

  &:global(.numeric) li {
    @apply relative list-none pb-4 list-outside flex items-start pl-0;
    counter-increment: custom-list-counter;
  }

  & :global(.numeric) {
    @apply font-serif font-bold text-base shrink-0;
    @apply relative mr-4 w-8 h-8 bg-green-100 rounded-full flex items-center justify-center;
  }

  & :global(.break-inside-avoid) {
    break-inside: avoid-column;
  }
}
